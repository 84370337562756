::-moz-selection {
	color: $white;
	background: $green;
}

::selection {
	color: $white;
	background: $green;
}

html {
	font-size: 62.5%;
	min-height: 100%;
}

body {
	font-size: 1.8rem;
	@include line-height(18, 28);
	min-width: 32rem;
	overflow-x: hidden;
	background: $white;
	color: $grey1;
	min-height: 100%;
	font-family: $regularFont;
	font-weight: 400;
	-webkit-font-smoothing: subpixel-antialiased;
}

.menu-open {
	overflow: hidden !important;
}

ul,
ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

*:focus {
	outline: none !important;
}

a {
	display: inline-block;
	@extend .transition;
	text-decoration: none;
	color: $green;
	&:hover {
		color: $grey1;
		text-decoration: none;
	}
}

.container {
	max-width: 129.6rem;
	--bs-gutter-x: 3rem;
	@include bp(smd-max) {
		--bs-gutter-x: 4rem;
	}
	&.sm {
		max-width: 108rem;
	}
	&.xs {
		max-width: 86.4rem;
	}
}

.row {
	--bs-gutter-x: 3rem;
}

img {
	max-width: 100%;
}

::-ms-clear {
	display: none;
}

input[type="text"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
input[type="text"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

.wrapper {
	min-height: 100vh;
	overflow-x: hidden;
	&.has-sticky-header {
		padding-top: 8rem;
		@include bp(smd-max) {
			padding-top: 7.6rem;
		}
	}
}

.transition {
	@include transition(all 0.5s);
}

.bg-props {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.absolute-pos {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
}

.cover-fit {
	object-fit: cover;
	object-position: center;
	-o-object-position: center;
}

.no-bullets {
	list-style: none;
	padding: 0;
	margin: 0;
}

.truncated-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.section-spacing {
	padding: 12rem 0;
}

.section-sm-spacing {
	padding: 8rem 0;
}
