.hero-banner {
	position: relative;
	padding-bottom: 45.84%;
	z-index: 0;
	@include bp(smd-max) {
		padding-bottom: 55%;
	}
	@include bp(sm-max) {
		height: 100vh;
		padding: 0;
		min-height: 60rem;
		padding: 12.3rem 0 4rem;
	}
	&.secondary {
		@include bp(smd-max) {
			padding-bottom: 48%;
		}
		@include bp(sm-max) {
			padding: 14rem 0 4rem;
		}
		.banner-img {
			left: auto;
			object-fit: inherit;
			width: auto;
			&.mobile {
				left: auto;
				right: 0;
				bottom: 0;
				max-width: 100%;
				height: auto;
				top: auto;
			}
		}
		.banner-content {
			h1 {
				@include bp(sm-max) {
					font-weight: 700;
				}
			}
		}
	}
	&.has-widget {
		padding: 20.5rem 0 5.3rem;
		@include bp(lg-max) {
			padding: 17rem 0 5rem;
		}
		@include bp(smd-max) {
			padding: 14rem 0 5rem;
		}
		@include bp(smd-max) {
			height: auto;
			min-height: 100vh;
		}
		.banner-content {
			position: static;
			@include translateY(0);
			@include bp(sm-max) {
				text-align: left;
			}
			.inner-content {
				width: 57.5rem;
				max-width: 100%;
				@include bp(sm-max) {
					width: 100%;
				}
				h1 {
					@include bp(sm-max) {
						text-align: center;
					}
				}
				p {
					margin: 0;
					@include bp(sm-max) {
						text-align: center;
					}
				}
				.booking-section {
					margin-top: 2.2rem;
					@include bp(sm-max) {
						margin-top: 3.1rem;
					}
				}
			}
		}
	}
	.banner-img {
		@extend .absolute-pos;
		@extend .cover-fit;
		z-index: -1;
		&.desktop {
			@include bp(sm-max) {
				display: none;
			}
		}
		&.mobile {
			display: none;
			@include bp(sm-max) {
				display: block;
			}
		}
	}
	.banner-content {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		@include translateY(-50%);
		@include bp(sm-max) {
			position: static;
			text-align: center;
			@include translateY(0);
		}
		&.is-centered {
			text-align: center;
			.inner-content {
				width: 62rem;
				max-width: 100%;
				margin: 0 auto;
				h1 {
					margin-bottom: 1.2rem;
					@include bp(sm-max) {
						margin-bottom: 0.8rem;
					}
				}
				p {
					max-width: inherit;
					margin-bottom: 2rem;
					@include bp(sm-max) {
						margin-bottom: 0.7rem;
					}
				}
				.banner-btn {
					.green-btn {
						width: 17.5rem;
					}
				}
			}
		}
		&.sm-width {
			.inner-content {
				max-width: 61.8rem;
				@include bp(lg-max) {
					max-width: 55rem;
				}
				@include bp(smd-max) {
					max-width: 48rem;
				}
				@include bp(sm-max) {
					max-width: inherit;
					margin: 0 -1rem;
				}
			}
		}
		h1 {
			text-transform: capitalize;
			margin-bottom: 1.9rem;
			@include bp(smd-max) {
				margin-bottom: 0.8rem;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		p {
			font-weight: 500;
			color: $grey;
			max-width: 51.8rem;
			@include bp(sm-max) {
				font-size: 1.6rem;
				@include line-height(16, 24);
				max-width: inherit;
			}
		}
	}
}

.rating-list {
	display: flex;
	justify-content: center;
	align-items: center;
	li {
		margin-right: 0.7rem;
		@include bp(sm-max) {
			margin-right: 0.5rem;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

.slider-navigation {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 3.2rem;
	@include bp(sm-max) {
		margin-top: 2.6rem;
	}
	.swiper-button-next,
	.swiper-button-prev {
		width: 2.2rem;
		height: 3.8rem;
		@extend .bg-props;
		background-size: 2.2rem;
		top: 9rem;
		margin: 0;
		@include bp(smd-max) {
			position: static;
			width: 1rem;
			height: 1.6rem;
		}
		&:after {
			display: none;
		}
	}
	.swiper-button-next {
		background-image: url("../images/right-angle.svg");
		right: 4rem;
		@include bp(lg-max) {
			right: 0;
		}
	}
	.swiper-button-prev {
		background-image: url("../images/left-angle.svg");
		left: 4rem;
		@include bp(lg-max) {
			left: 0;
		}
	}
	.swiper-pagination {
		position: static;
		display: flex;
		justify-content: center;
		@include bp(smd-max) {
			width: auto;
			margin: 0 2.7rem;
		}
		.swiper-pagination-bullet {
			height: 1rem;
			width: 1rem;
			background-color: $light-grey;
			opacity: 1;
			margin-left: 0;
			margin-right: 2rem;
			@include bp(smd-max) {
				height: 0.7rem;
				width: 0.7rem;
			}
			&:last-child {
				margin-right: 0;
			}
			&.swiper-pagination-bullet-active {
				background-color: $grey;
			}
		}
	}
}

.booking-section {
	padding: 12rem 0 0;
	@include bp(lg-max) {
		padding: 8rem 0 0;
	}
	@include bp(sm-max) {
		padding: 0;
	}
	&.is-transparent {
		padding: 0;
		.booking-widget {
			box-shadow: none;
			padding: 1.2rem 2rem 4.3rem;
			background-color: rgba($blue, 0.8);
			margin: 0;
			@include bp(sm-max) {
				padding: 1.8rem 2.6rem 3.8rem;
			}
			.widget-row {
				.widget-col {
					width: 50%;
					@include bp(sm-max) {
						width: 100%;
					}
					&.lg {
						width: 50%;
						@include bp(sm-max) {
							width: 100%;
						}
					}
					&.btn-col {
						width: 50%;
						margin-top: 0.9rem;
						margin-bottom: 0;
						@include bp(sm-max) {
							width: 100%;
						}
						.green-btn {
							width: 100%;
							@include bp(sm-max) {
								width: 15rem;
							}
						}
					}
					.dk-select .dk-selected,
					.form-control {
						height: 4.4rem;
						line-height: 4rem;
					}
				}
			}
		}
	}
	&.is-overlapped {
		padding: 0;
		margin-top: -7.4rem;
		position: relative;
		z-index: 1;
	}
	.booking-widget {
		padding: 2.8rem 4.2rem 3.6rem;
		background: $blue;
		box-shadow: 0 2.2rem 5rem -1.2rem rgba(16, 24, 40, 0.2);
		border-radius: 0.8rem;
		@include bp(lg-max) {
			padding: 3rem 2rem;
		}
		@include bp(sm-max) {
			padding: 1.9rem 2.6rem;
			margin-top: -15rem;
			z-index: 1;
			position: relative;
		}
		.widget-row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -1rem -1.2rem;
			.widget-col {
				width: 19.55%;
				padding: 0 1rem;
				margin-bottom: 1.2rem;
				@include bp(lg-max) {
					width: 50%;
				}
				@include bp(sm-max) {
					width: 100%;
				}
				&.lg {
					width: 23.2%;
					@include bp(lg-max) {
						width: 50%;
					}
					@include bp(sm-max) {
						width: 100%;
					}
				}
				&.btn-col {
					width: 14.5%;
					align-self: flex-end;
					@include bp(lg-max) {
						width: 100%;
						text-align: center;
					}
					@include bp(sm-max) {
						text-align: left;
						margin-top: 0.9rem;
						margin-bottom: 1.9rem;
					}
					.green-btn {
						width: 100%;
						max-width: 100%;
						@include bp(lg-max) {
							width: 23rem;
						}
						@include bp(sm-max) {
							width: 15rem;
						}
					}
				}
				.form-group {
					margin: 0;
					label {
						font-size: 1.4rem;
						font-weight: 600;
						margin-bottom: 1rem;
						color: $grey;
					}
				}
			}
		}
	}
}

.chip {
	display: inline-block;
	padding: 0.4rem 2rem;
	background: $blue;
	border-radius: 1.6rem;
	font-size: 1.6rem;
	@include line-height(16, 24);
	color: $grey;
	&.active {
		background: $green;
		color: $white;
	}
	&.secondary {
		padding: 0.6rem 1.2rem;
		@include line-height(16, 16);
		background: rgba(255, 255, 255, 0.7);
		border-radius: 0.4rem;
	}
	&.is-white {
		background: $white;
		color: $grey;
		&.active {
			background: $green;
			color: $white;
		}
	}
}

.pagination-wrapper {
	display: flex;
	justify-content: center;
	margin-top: 6rem;
	@include bp(sm-max) {
		margin-top: 3.9rem;
	}
	.pagination-outer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 0.1rem solid $light-grey4;
		width: 83.4rem;
		max-width: 100%;
		padding: 0.8rem 0;
		font-size: 1.4rem;
		@include line-height(14, 20);
		.prev-nxt-btn {
			color: $grey;
			display: inline-flex;
			align-items: center;
			padding: 0.8rem 1.8rem;
			&.is-next {
				.icon {
					margin-right: 0;
					margin-left: 1.5rem;
				}
			}
			&.disabled {
				color: $light-grey;
				cursor: not-allowed;
				.icon {
					svg {
						path {
							stroke: $light-grey;
						}
					}
				}
			}
			&:hover {
				&:not(.disabled) {
					opacity: 0.8;
				}
			}
			.icon {
				display: inline-block;
				line-height: 0;
				margin-right: 1.5rem;
				svg {
					path {
						@extend .transition;
						stroke: $grey;
					}
				}
			}
		}
		.page-label {
			color: $grey;
			display: none;
			@include bp(xs-max) {
				display: inline-block;
			}
		}
		.page-no {
			display: flex;
			align-items: center;
			@include bp(xs-max) {
				display: none;
			}
			li {
				height: 4rem;
				width: 4rem;
				margin-right: 0.2rem;
				border-radius: 0.4rem;
				&:last-child {
					margin-right: 0;
				}
				a {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
					width: 100%;
					color: $grey;
					border-radius: 0.4rem;
					&:hover,
					&.active {
						background-color: $blue;
					}
				}
			}
		}
	}
}

.breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 1.6rem;
	@include line-height(16, 20);
	font-weight: 600;
	color: $grey1;
	margin-bottom: 3rem;
	@include bp(lg-max) {
		margin-bottom: 2.5rem;
	}
	@include bp(sm-max) {
		margin-bottom: 2rem;
	}
	li {
		margin-right: 1.1rem;
		&:last-child {
			margin-right: 0;
			&:after {
				display: none;
			}
		}
		&:after {
			content: "";
			display: inline-block;
			width: 0.7rem;
			height: 1.3rem;
			background-image: url("../images/right-angle-black.svg");
			background-size: 0.7rem;
			background-repeat: no-repeat;
			background-position: center;
			position: relative;
			top: 0.2rem;
			margin-left: 1.1rem;
		}
	}
	a {
		color: $grey1;
		&:hover {
			color: $green;
		}
	}
}

.timeline {
	li {
		position: relative;
		padding-left: 2.6rem;
		margin-bottom: 2.3rem;
		&:last-child {
			margin-bottom: 0;
			&::after {
				display: none;
			}
		}
		&::before {
			content: "";
			position: absolute;
			left: 0.2rem;
			top: 0.8rem;
			display: inline-block;
			height: 1rem;
			width: 1rem;
			background: $grey1;
			border-radius: 50%;
		}
		&:after {
			content: "";
			position: absolute;
			top: 1rem;
			left: 0.6rem;
			bottom: -3.2rem;
			width: 0.1rem;
			background-color: $grey1;
		}
		h6 {
			font-weight: 600;
			@include line-height(16, 24);
			letter-spacing: 0.01rem;
			margin-bottom: 0.4rem;
		}
		.time {
			display: block;
			font-size: 1.6rem;
			@include line-height(16, 24);
			letter-spacing: 0.01rem;
		}
	}
}

.category-accordion {
	margin-bottom: 3rem;
	@include bp(smd-max) {
		margin-bottom: 2.6rem;
	}
	@include bp(sm-max) {
		margin-bottom: 2.1rem;
	}
	&:last-child {
		margin-bottom: 0;
	}
	.category-title {
		padding: 1.5rem 4.4rem 1.5rem 2.2rem;
		border: 0.1rem solid $light-grey;
		border-radius: 0.4rem;
		font-size: 2.2rem;
		font-weight: 700;
		color: $grey;
		cursor: pointer;
		position: relative;
		@extend .transition;
		@include bp(smd-max) {
			font-size: 2rem;
		}
		@include bp(sm-max) {
			font-size: 1.8rem;
			@include line-height(18, 24);
			padding: 1.7rem 3.5rem 1.7rem 1.5rem;
		}
		&.expanded {
			background: $light-grey1;
			&:after {
				@include rotate(180deg);
			}
		}
		&:after {
			content: "";
			position: absolute;
			right: 2rem;
			top: calc(50% - 4rem);
			width: 1.4rem;
			height: 8rem;
			background-image: url("../images/down-arrow.svg");
			background-size: 1.4rem;
			background-repeat: no-repeat;
			background-position: center;
			@extend .transition;
			@include bp(sm-max) {
				right: 1.3rem;
			}
		}
	}

	.inner-accordion-wrapper {
		padding-top: 1.6rem;
		display: none;
	}

	.accordion-outer {
		border: 0.05rem solid $light-grey;
		border-radius: 0.4rem;
		margin-bottom: 1.5rem;
		&:last-child {
			margin-bottom: 0;
		}
		.accordion-title {
			padding: 1.7rem 4.4rem 1.7rem 2.2rem;
			font-size: 1.8rem;
			@include line-height(18, 24);
			font-weight: 600;
			color: $grey;
			cursor: pointer;
			position: relative;
			@include bp(sm-max) {
				font-size: 1.7rem;
			}
			@include bp(sm-max) {
				font-size: 1.6rem;
				@include line-height(16, 24);
				padding: 1.7rem 3.5rem 1.7rem 1.5rem;
			}
			&.expanded {
				&:after {
					@include rotate(180deg);
				}
			}
			&:after {
				content: "";
				position: absolute;
				right: 2rem;
				top: calc(50% - 4rem);
				width: 1.4rem;
				height: 8rem;
				background-image: url("../images/down-arrow.svg");
				background-size: 1.4rem;
				background-repeat: no-repeat;
				background-position: center;
				@extend .transition;
				@include bp(sm-max) {
					right: 1.3rem;
				}
			}
		}
		.accordion-body {
			padding: 0 4.4rem 1.8rem 2.2rem;
			font-size: 1.6rem;
			@include line-height(16, 24);
			display: none;
			@include bp(lg-max) {
				padding: 0 2.2rem 1.8rem;
			}
			@include bp(sm-max) {
				padding: 0 1.6rem 1.9rem;
			}
			&.lg-font {
				font-size: 1.8rem;
				@include line-height(18, 24);
				padding-right: 2.2rem;
			}
		}
	}
}

.cms-content {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 600;
	}
	strong {
		font-weight: 600;
	}
	ol {
		padding-left: 2rem;
		margin-top: 0;
		margin-bottom: 1rem;
	}
	ul {
		@extend .custom-list;
	}
	ol {
		list-style: decimal;
	}
}

.custom-list {
	li {
		position: relative;
		padding-left: 2.4rem;
		@include line-height(16, 24);
		margin-bottom: 1.4rem;
		@include bp(sm-max) {
			margin-bottom: 1rem;
		}
		&:last-child {
			margin: 0;
		}
		&::before {
			content: "";
			position: absolute;
			left: 0.9rem;
			top: 1rem;
			height: 0.6rem;
			width: 0.6rem;
			border-radius: 50%;
			background-color: $grey1;
		}
	}
}

.page-not-found {
	padding: 16rem 0 12rem;
	text-align: center;
	@include bp(lg-max) {
		padding: 12rem 0;
	}
	@include bp(sm-max) {
		padding: 10rem 0;
	}
	.thumb {
		display: inline-flex;
		margin-bottom: 4.4rem;
	}
	h2 {
		margin-bottom: 0.9rem;
		text-transform: capitalize;
	}
	p {
		font-size: 2.2rem;
		@include line-height(22, 34);
		margin-bottom: 3rem;
	}
	.green-btn {
		width: 21.6rem;
		max-width: 100%;
	}
}
