.about-business {
	padding: 11.3rem 0 12rem;
	text-align: center;
	@include bp(lg-max) {
		padding: 9rem 0 10rem;
	}
	@include bp(sm-max) {
		padding: 7.8rem 0 8rem;
	}
	h2 {
		margin-bottom: 3rem;
		@include bp(sm-max) {
			margin-bottom: 1.3rem;
		}
	}
	p {
		font-weight: 500;
	}
}

.local-brands {
	background-color: $blue;
	padding: 7.4rem 0 5.8rem;
	@include bp(sm-max) {
		padding: 7.2rem 0;
	}
	h2 {
		margin-bottom: 5rem;
		text-align: center;
		@include bp(sm-max) {
			margin-bottom: 3.4rem;
		}
	}
	.brand-row {
		margin: 0 -4rem -4rem;
		display: flex;
		flex-wrap: wrap;
		@include bp(xlg-max) {
			margin: 0 -1.5rem -4rem;
		}
		.brand-col {
			padding: 0 4rem;
			flex: 0 0 33.33%;
			max-width: 33.33%;
			margin-bottom: 4rem;
			border-right: 0.1rem solid $blue1;
			@include bp(xlg-max) {
				padding: 0 1.5rem;
			}
			@include bp(smd-max) {
				flex: 0 0 100%;
				max-width: 100%;
				border-right: 0;
				border-bottom: 0.1rem solid $blue1;
				padding-bottom: 4rem;
				text-align: center;
			}
			&:last-child {
				border: 0;
				padding-bottom: 0;
			}
			.brand-info {
				.logo {
					display: inline-flex;
					height: 9rem;
					margin-bottom: 2rem;
					@include bp(sm-max) {
						height: 6rem;
						margin-bottom: 1.8rem;
						&.mob-lg {
							height: 8rem;
						}
					}
				}
				h3 {
					margin-bottom: 0.6rem;
					text-transform: capitalize;
				}
				p {
					@include line-height(18, 26);
				}
			}
		}
	}
}

.earned-numbers {
	padding: 11.4rem 0;
	@include bp(lg-max) {
		padding: 8rem 0;
	}
	&.has-bg {
		padding: 8rem 0;
		background-color: $blue;
		@include bp(smd-max) {
			padding: 6.8rem 0;
		}
	}
	.number-row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5rem -2.8rem;
		.number-col {
			padding: 0 1.5rem;
			margin-bottom: 2.8rem;
			flex: 0 0 25%;
			max-width: 25%;
			text-align: center;
			@include bp(sm-max) {
				flex: 0 0 50%;
				max-width: 50%;
			}
			.no {
				@extend h2;
				margin-bottom: 0.9rem;
				display: block;
				@include bp(sm-max) {
					margin-bottom: 0.6rem;
				}
			}
			.no-label {
				display: block;
				font-weight: 600;
				@include bp(sm-max) {
					font-size: 1.6rem;
				}
			}
		}
	}
}

.testimonials {
	padding: 7.4rem 0;
	background-color: $blue;
	@include bp(sm-max) {
		padding: 7.1rem 0;
	}
	h2 {
		text-align: center;
		margin-bottom: 5rem;
		@include bp(sm-max) {
			margin-bottom: 3.2rem;
		}
	}
	.testimonial-outer {
		text-align: center;
		max-width: 83.4rem;
		margin: 0 auto;
		.dp {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 7rem;
			width: 7rem;
			overflow: hidden;
			border-radius: 50%;
			margin-bottom: 3rem;
			@include bp(sm-max) {
				margin-bottom: 1.9rem;
			}
			img {
				height: 100%;
				width: 100%;
				@extend .cover-fit;
			}
		}
		.content {
			line-height: 2.6rem;
			letter-spacing: 0.02rem;
			font-weight: 500;
			padding: 0 1rem;
			position: relative;
			z-index: 0;
			margin-bottom: 0.9rem;
			@include bp(smd-max) {
				padding: 0;
			}
			@include bp(sm-max) {
				font-size: 1.6rem;
				@include line-height(16, 24);
				letter-spacing: 0.02rem;
				margin-bottom: 0.6rem;
			}
			&::before {
				content: "";
				position: absolute;
				top: -2.1rem;
				left: -0.2rem;
				width: 6rem;
				height: 4.5rem;
				background-image: url("../images/quote-icon.png");
				@extend .bg-props;
				z-index: -1;
				@include bp(sm-max) {
					width: 4.6rem;
					height: 3.5rem;
					top: -0.8rem;
				}
			}
		}
		.rating-list {
			margin-bottom: 1.1rem;
			@include bp(sm-max) {
				margin-bottom: 0.9rem;
			}
		}
		.location {
			display: block;
			font-size: 2.4rem;
			@include line-height(24, 32);
			letter-spacing: 0.01rem;
			font-weight: 600;
			color: $grey;
			@include bp(smd-max) {
				font-size: 2.2rem;
			}
			@include bp(sm-max) {
				font-size: 2rem;
				@include line-height(20, 32);
			}
		}
	}
}

.pickup-drop-location {
	padding: 11.4rem 0;
	@include bp(lg-max) {
		padding: 8.2rem 0;
	}
	.title-icon {
		@include bp(sm-max) {
			margin-bottom: 4.4rem;
		}
	}
	.location-row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5rem -1.8rem;
		.location-col {
			padding: 0 1.5rem;
			margin-bottom: 1.8rem;
			flex: 0 0 50%;
			max-width: 50%;
			@include bp(sm-max) {
				flex: 0 0 100%;
				max-width: 100%;
			}
			.location-card {
				padding: 3rem 1.6rem;
				background: $white;
				box-shadow: 0 0.4rem 2rem rgba(68, 8, 21, 0.1);
				border-radius: 0.8rem;
				height: 100%;
				@include bp(sm-max) {
					height: auto;
				}
				h5 {
					font-weight: 600;
					margin-bottom: 2.2rem;
				}
				.info-list {
					li {
						display: flex;
						margin-bottom: 1.4rem;
						font-size: 1.6rem;
						@include line-height(16, 24);
						@include bp(sm-max) {
							margin-bottom: 2.1rem;
						}
						&:last-child {
							margin-bottom: 0;
						}
						.icon {
							width: 2.2rem;
							min-width: 2.2rem;
							text-align: center;
						}
						.value {
							width: calc(100% - 2.2rem);
							padding-left: 1.5rem;
						}
					}
				}
				.note {
					margin-top: 1.4rem;
					margin-bottom: 0;
					font-size: 1.4rem;
					@include line-height(14, 16);
				}
				.direction-link {
					display: inline-flex;
					align-items: center;
					margin-top: 1.9rem;
					font-weight: 600;
					text-decoration: underline;
					&:hover {
						.icon {
							right: -0.5rem;
						}
					}
					.icon {
						line-height: 0;
						margin-left: 1rem;
						@extend .transition;
						position: relative;
						right: 0;
					}
				}
			}
		}
	}
}

.waiheke-offering {
	padding: 0 0 12rem;
	@include bp(lg-max) {
		padding: 0 0 8rem;
	}
	&.lg-pad {
		padding: 0 0 14rem;
		@include bp(lg-max) {
			padding: 0 0 11rem;
		}
		@include bp(smd-max) {
			padding: 0 0 8rem;
		}
	}
	&.mob-p-0 {
		@include bp(smd-max) {
			padding-bottom: 0;
		}
	}
	.offer-wrapper {
		background: $blue;
		border-radius: 0.8rem;
		padding: 7.9rem 11.6rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		@include bp(xlg-max) {
			padding: 7.9rem 8rem;
		}
		@include bp(lg-max) {
			padding: 6rem 4rem;
		}
		@include bp(sm-max) {
			flex-direction: column;
			padding: 4rem 1.6rem;
		}
		h2 {
			margin: 0;
			width: 62%;
			font-weight: 600;
			text-transform: capitalize;
			@include bp(lg-max) {
				width: 65%;
				font-size: 3rem;
			}
			@include bp(sm-max) {
				width: 100%;
				font-size: 2.6rem;
				@include line-height(26, 36);
				text-align: center;
				margin-bottom: 2rem;
			}
		}
		.green-btn {
			width: 18.6rem;
		}
	}
}

.contact-section {
	background-color: $blue;
	padding: 7.4rem 0;
	@include bp(sm-max) {
		padding: 7rem 0;
	}
	.contact-row {
		display: flex;
		margin: 0 -1.5rem;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.contact-info {
		flex: 0 0 50%;
		max-width: 52rem;
		padding: 0.7rem 1.5rem 0;
		@include bp(smd-max) {
			flex: 0 0 100%;
			max-width: 100%;
			padding-top: 0;
		}
		.inner-info {
			margin-bottom: 3.6rem;
			@include bp(sm-max) {
				margin-bottom: 1.8rem;
			}
			&:last-child {
				margin-bottom: 0;
			}
			h3 {
				font-weight: 700;
				margin-bottom: 1.4rem;
				text-transform: capitalize;
				@include bp(sm-max) {
					margin-bottom: 0.8rem;
				}
			}
			p {
				@include line-height(18, 26);
				@include bp(sm-max) {
					font-size: 1.6rem;
					@include line-height(16, 24);
				}
				a {
					color: $grey1;
					&:hover {
						color: $green;
					}
					&.mail-link {
						font-weight: 600;
						color: $green;
						text-decoration: underline;
						&:hover {
							color: $grey1;
						}
					}
				}
			}
			.social-links {
				display: flex;
				align-items: center;
				li {
					margin-right: 2.5rem;
					&:last-child {
						margin-right: 0;
					}
					a {
						&:hover {
							opacity: 0.7;
						}
					}
				}
			}
		}
	}
	.form-col {
		flex: 0 0 50%;
		max-width: 50%;
		padding: 0 1.5rem;
		@include bp(smd-max) {
			flex: 0 0 100%;
			max-width: 100%;
			margin-top: 5.4rem;
		}
		.submit-btn {
			margin-top: 2.4rem;
			.green-btn {
				width: 21.6rem;
				max-width: 100%;
			}
		}
	}
	.bottom-contact {
		margin: 4rem 0 -5.4rem;
		font-size: 1.4rem;
		@include line-height(14, 23);
		.bottom-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0 -1.5rem;
			border-top: 0.1rem solid $grey1;
			padding-top: 1.8rem;
			@include bp(smd-max) {
				text-align: center;
			}
			> * {
				@include bp(smd-max) {
					width: 100%;
				}
			}
			.created-by {
				a {
					color: $grey1;
					font-weight: 500;
				}
			}
			.bottom-links {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				@include bp(smd-max) {
					justify-content: center;
					margin: 1rem 0;
				}
				li {
					margin-right: 1.6rem;
					position: relative;
					@include bp(sm-max) {
						width: 100%;
						margin-right: 0;
						margin-bottom: 0.2rem;
					}
					&:last-child {
						margin: 0;
						&:after {
							display: none;
						}
					}
					&:after {
						content: "";
						position: absolute;
						right: -0.8rem;
						top: 0.6rem;
						width: 0.1rem;
						height: 1.2rem;
						background-color: $grey1;
						@include bp(sm-max) {
							display: none;
						}
					}
					a {
						color: $grey1;
					}
				}
			}
		}
	}
}

.location-map {
	position: relative;
	padding-bottom: 40.3%;
	@include bp(sm-max) {
		padding-bottom: 0;
	}
	iframe {
		display: block;
		border: 0;
		@extend .absolute-pos;
		@include bp(sm-max) {
			position: static !important;
			min-height: calc(100vh - 7.6rem);
		}
	}
}

.waiheke-places {
	padding: 13.4rem 0 0;
	@include bp(lg-max) {
		padding: 11rem 0 0;
	}
	@include bp(smd-max) {
		padding: 9rem 0 0;
	}
	@include bp(sm-max) {
		padding: 7.8rem 0 0 0 0;
	}
	.place-title {
		text-align: center;
		width: 105rem;
		max-width: 100%;
		margin: 0 auto 6rem;
		@include bp(lg-max) {
			margin-bottom: 5rem;
		}
		@include bp(smd-max) {
			margin-bottom: 4rem;
		}
		@include bp(sm-max) {
			margin-bottom: 3rem;
		}
		h2 {
			margin-bottom: 1.9rem;
			@include bp(sm-max) {
				margin-bottom: 1.3rem;
			}
		}
		p {
			font-size: 2.2rem;
			@include line-height(22, 34);
			@include bp(lg-max) {
				font-size: 2rem;
			}
			@include bp(smd-max) {
				font-size: 1.8rem;
				@include line-height(18, 26);
			}
		}
	}
}

.place-grid-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1.5rem;
	@include bp(xlg-max) {
		margin: 0 -1rem;
	}
	&.two-cards {
		.places-left {
			width: 100%;
			@include bp(sm-max) {
				margin-bottom: 0;
			}
			.left-row {
				.left-col {
					@include bp(sm-max) {
						margin-bottom: 2rem;
						&:last-child {
							margin-bottom: 0;
						}
					}
					.place-card {
						margin-bottom: 0;
						padding-bottom: 86.1%;
						@include bp(xs-max) {
							padding-bottom: 86.4% !important;
						}
					}
				}
			}
		}
	}
	.places-left {
		width: 50%;
		padding: 0 1.5rem;
		@include bp(xlg-max) {
			padding: 0 1rem;
		}
		@include bp(smd-max) {
			width: 100%;
			margin-bottom: 3rem;
		}
		@include bp(sm-max) {
			margin-bottom: 2rem;
		}
		.left-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.left-col {
				width: calc(50% - 1.5rem);
				@include bp(xs-max) {
					width: 100%;
				}
				.place-card {
					padding-bottom: 54.09%;
				}
			}
		}
	}
	.places-middle,
	.places-right {
		width: 25%;
		padding: 0 1.5rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include bp(xlg-max) {
			padding: 0 1rem;
		}
		@include bp(smd-max) {
			width: 50%;
		}
		@include bp(xs-max) {
			width: 100%;
		}
		.place-card {
			height: calc(61.5% - 1.5rem);
			padding: 0 !important;
			margin: 0;
			@include bp(sm-max) {
				height: calc(61.5% - 1rem);
			}
			@include bp(xs-max) {
				margin-bottom: 2rem !important;
				padding-bottom: 75.6% !important;
			}
			&.sm-height {
				height: calc(38.5% - 1.5rem);
				@include bp(sm-max) {
					height: calc(38.5% - 1rem);
				}
			}
			.place-banner {
				@include bp(smd-max) {
					position: static !important;
				}
				@include bp(xs-max) {
					position: absolute !important;
				}
			}
		}
	}
	.places-right {
		.place-card {
			height: calc(45.5% - 1.5rem);
			@include bp(sm-max) {
				height: calc(45.5% - 1rem);
			}
			&.sm-height {
				height: calc(54.5% - 1.5rem);
				@include bp(sm-max) {
					height: calc(54.5% - 1rem);
				}
			}
		}
	}
	.place-card {
		position: relative;
		overflow: hidden;
		box-shadow: 0 0.8rem 2rem rgba(0, 0, 0, 0.15);
		border-radius: 2rem;
		padding-bottom: 55.67%;
		margin-bottom: 3rem;
		display: block;
		@include bp(sm-max) {
			border-radius: 1rem;
			margin-bottom: 2rem;
		}
		@include bp(xs-max) {
			width: 100% !important;
			height: 0 !important;
			padding-bottom: 75.6% !important;
		}
		&:hover {
			img {
				@include scale(1.05);
			}
		}
		&.sm-height {
			padding-bottom: 53.08%;
		}
		.place-banner {
			@extend .absolute-pos;
			@extend .cover-fit;
			@extend .transition;
		}
		.place-name {
			z-index: 1;
			position: absolute;
			left: 2rem;
			bottom: 2rem;
			background: $white;
			border-radius: 1rem;
			display: inline-flex;
			align-items: center;
			padding: 1.7rem 1.6rem;
			color: $grey;
			font-weight: 500;
			text-decoration: underline;
			max-width: calc(100% - 4rem);
			@include line-height(18, 22);
			@include bp(xlg-max) {
				padding: 1rem 1.2rem;
				font-size: 1.6rem;
				left: 1.5rem;
				bottom: 1.5rem;
			}
			@include bp(sm-max) {
				padding: 1.5rem 1.3rem;
				font-size: 1.6rem;
			}
			.icon {
				line-height: 0;
				margin-right: 1.5rem;
				@include bp(xlg-max) {
					margin-right: 0.6rem;
				}
				@include bp(sm-max) {
					margin-right: 1.2rem;
				}
			}
		}
	}
}

.explore-about {
	padding: 13.5rem 0 14rem;
	@include bp(lg-max) {
		padding: 11rem 0;
	}
	@include bp(smd-max) {
		padding: 9rem 0;
	}
	@include bp(sm-max) {
		padding: 5.9rem 0 8rem;
	}
	.title-block {
		text-align: center;
		max-width: 105rem;
		margin: 0 auto 6rem;
		@include bp(lg-max) {
			margin-bottom: 5rem;
		}
		@include bp(smd-max) {
			margin-bottom: 4rem;
		}
		@include bp(sm-max) {
			margin-bottom: 3rem;
		}
		h2 {
			margin-bottom: 1.9rem;
			@include bp(sm-max) {
				margin-bottom: 1.3rem;
			}
		}
		p {
			font-size: 2.2rem;
			@include line-height(22, 34);
			@include bp(lg-max) {
				font-size: 2rem;
			}
			@include bp(smd-max) {
				font-size: 1.8rem;
				@include line-height(18, 26);
			}
		}
	}
	.about-banner {
		border-radius: 2rem;
		overflow: hidden;
		padding-bottom: 56.35%;
		position: relative;
		@include bp(sm-max) {
			padding-bottom: 130%;
			border-radius: 1rem;
		}
		@include bp(xs-max) {
			padding-bottom: 178.4%;
		}
		img {
			@extend .absolute-pos;
			@extend .cover-fit;
			@extend .transition;
			&.desktop {
				@include bp(sm-max) {
					display: none;
				}
			}
			&.mobile {
				display: none;
				@include bp(sm-max) {
					display: block;
				}
			}
		}
	}
}

.explore-waiheke-island {
	padding: 0.6rem 0 14rem;
	@include bp(lg-max) {
		padding: 0 0 11rem;
	}
	@include bp(smd-max) {
		padding: 0 0 9rem;
	}
	@include bp(sm-max) {
		padding: 0 0 8.4rem;
	}
	h2 {
		text-align: center;
		margin-bottom: 6.4rem;
		@include bp(lg-max) {
			margin-bottom: 5rem;
		}
		@include bp(smd-max) {
			margin-bottom: 4rem;
		}
		@include bp(sm-max) {
			margin-bottom: 3rem;
		}
	}
	.explore-row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5rem;
		@include bp(smd-max) {
			margin-bottom: -3rem;
		}
		.explore-col {
			padding: 0 1.5rem;
			width: 25%;
			@include bp(smd-max) {
				width: 50%;
				margin-bottom: 3rem;
			}
			@include bp(xs-max) {
				width: 100%;
			}
			.island-activity {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				&.reverse {
					flex-direction: column-reverse;
					@include bp(xs-max) {
						flex-direction: column;
					}
					.img-block {
						margin-bottom: 0;
						margin-top: 1.8rem;
						border-radius: 0 0 2rem 2rem;
						@include bp(xs-max) {
							border-radius: 1rem 1rem 0 0;
							margin-top: 0;
							margin-bottom: 2rem;
						}
					}
				}
				&:hover {
					.img-block {
						img {
							@include scale(1.05);
						}
					}
				}
				.img-block {
					overflow: hidden;
					padding-bottom: 111.8%;
					position: relative;
					margin-bottom: 1.8rem;
					box-shadow: 0 0.8rem 2rem rgba(0, 0, 0, 0.15);
					border-radius: 2rem 2rem 0 0;
					@include bp(xs-max) {
						padding-bottom: 75.5%;
						margin-bottom: 2rem;
						border-radius: 1rem 1rem 0 0;
					}
					img {
						@extend .absolute-pos;
						@extend .cover-fit;
						@extend .transition;
					}
				}
				.content {
					@include bp(xs-max) {
						height: auto !important;
					}
					h3 {
						@include line-height(22, 30);
						font-weight: 700;
						margin-bottom: 0.6rem;
						@include bp(sm-max) {
							font-size: 1.8rem;
						}
					}
					p {
						@include bp(sm-max) {
							@include line-height(18, 26);
						}
						.learn-more-link {
							color: $grey;
							font-weight: 600;
							&:hover {
								color: $green;
							}
						}
					}
				}
			}
		}
	}
}

.help-page {
	padding: 14rem 0 12rem;
	@include bp(smd-max) {
		padding: 10rem 0 8rem;
	}
}

.blog-page {
	padding: 7rem 0 12rem;
	@include bp(lg-max) {
		padding: 6rem 0 10rem;
	}
	@include bp(smd-max) {
		padding: 5rem 0 8rem;
	}
	&.is-blog-detail {
		padding: 3.9rem 0 0;
		@include bp(xlg-max) {
			padding: 6rem 0 0;
		}
		@include bp(smd-max) {
			padding: 2.4rem 0 0;
		}
	}
	.blog-hero-banner {
		margin-bottom: 6rem;
		@include bp(sm-max) {
			box-shadow: 0 0.2rem 2rem rgba(44, 54, 137, 0.1);
			border-radius: 0.6rem;
			overflow: hidden;
			margin-bottom: 2rem;
		}
		&:hover {
			.blog-banner {
				img {
					@include scale(1.05);
				}
			}
		}
		.blog-banner {
			position: relative;
			border-radius: 2rem;
			padding-bottom: 40.3%;
			display: block;
			overflow: hidden;
			@include bp(sm-max) {
				border-radius: 0.6rem 0.6rem 0 0;
				padding-bottom: 57%;
			}
			@media (max-width: 767.98px) and (orientation: landscape) {
				padding-bottom: 45%;
			}
			img {
				@extend .absolute-pos;
				@extend .cover-fit;
				@extend .transition;
			}
		}
		.blog-banner-content {
			padding-top: 2.7rem;
			@include bp(lg-max) {
				padding-top: 2rem;
			}
			@include bp(sm-max) {
				display: flex;
				flex-direction: column-reverse;
				padding: 2rem 1.6rem;
			}
			.h1 {
				font-weight: 600;
				margin-bottom: 1.4rem;
				color: $grey;
				@include bp(sm-max) {
					font-size: 1.8rem;
					@include line-height(18, 24);
					margin-bottom: 0.6rem;
					font-weight: 600;
				}
				a {
					color: $grey;
					display: block;
				}
			}
			p {
				font-size: 1.6rem;
				@include line-height(16, 24);
				margin-bottom: 0.5rem;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				display: none;
				@include bp(sm-max) {
					display: -webkit-box;
				}
			}
			.date {
				display: block;
				font-size: 1.6rem;
				@include line-height(16, 18);
				@include bp(sm-max) {
					margin-bottom: 1.2rem;
				}
			}
			.read-more {
				font-size: 1.6rem;
				@include line-height(16, 19);
				text-decoration: underline;
				color: $grey;
				font-weight: 500;
				display: none;
				@include bp(sm-max) {
					display: inline-block;
				}
				&:hover {
					color: $green;
				}
			}
		}
	}
}

.blog-list-row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1.5rem -3.1rem;
	@include bp(sm-max) {
		margin-bottom: -1.8rem;
	}
	.blog-list-col {
		width: 50%;
		padding: 0 1.5rem;
		margin-bottom: 3.1rem;
		@include bp(sm-max) {
			width: 100%;
			margin-bottom: 1.8rem;
		}
	}
	.blog-card {
		background: $white;
		box-shadow: 0 0.2rem 2rem rgba(44, 54, 137, 0.1);
		border-radius: 0.6rem;
		overflow: hidden;
		&:hover {
			.blog-thumb {
				img {
					@include scale(1.05);
				}
			}
		}
		.blog-thumb {
			position: relative;
			border-radius: 0.6rem 0.6rem 0 0;
			padding-bottom: 56.5%;
			display: block;
			overflow: hidden;
			@media (max-width: 767.98px) and (orientation: landscape) {
				padding-bottom: 45%;
			}
			img {
				@extend .absolute-pos;
				@extend .cover-fit;
				@extend .transition;
			}
		}
		.blog-content {
			padding: 2rem;
			@include bp(sm-max) {
				padding: 2rem 1.6rem;
			}
			.date {
				display: block;
				font-size: 1.6rem;
				@include line-height(16, 18);
				margin-bottom: 1.4rem;
				@include bp(sm-max) {
					margin-bottom: 1.2rem;
				}
			}
			h5 {
				@include line-height(18, 24);
				margin-bottom: 0.5rem;
				font-weight: 600;
				a {
					display: block;
					color: $grey;
					&:hover {
						color: $green;
					}
				}
			}
			p {
				font-size: 1.6rem;
				@include line-height(16, 24);
				margin-bottom: 0.9rem;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				@include bp(sm-max) {
					margin-bottom: 0.5rem;
				}
			}
			.read-more {
				font-size: 1.6rem;
				@include line-height(16, 19);
				text-decoration: underline;
				color: $grey;
				font-weight: 500;
				&:hover {
					color: $green;
				}
			}
		}
	}
}

.recent-blogs {
	padding: 11.5rem 0;
	@include bp(lg-max) {
		padding: 8rem 0;
	}
	&.lg-pad {
		padding: 14rem 0;
		@include bp(lg-max) {
			padding: 12rem 0;
		}
		@include bp(smd-max) {
			padding: 10rem 0;
		}
		@include bp(sm-max) {
			padding: 8rem 0;
		}
	}
	h2 {
		text-align: center;
		margin-bottom: 5rem;
		@include bp(sm-max) {
			margin-bottom: 3.2rem;
		}
	}
	.see-all-btn {
		text-align: center;
		margin-top: 5rem;
		.green-btn {
			width: 18.6rem;
			max-width: 100%;
		}
	}
}

.blog-detail-wrapper {
	.blog-detail-title {
		margin: 4.7rem 0 4.2rem;
		@include bp(lg-max) {
			margin: 3rem 0 4rem;
		}
		@include bp(sm-max) {
			margin: 2rem 0;
		}
		h1 {
			font-weight: 600;
			margin-bottom: 1.4rem;
			color: $grey;
			@include bp(sm-max) {
				font-size: 1.8rem;
				@include line-height(18, 24);
				margin-bottom: 1.3rem;
				font-weight: 600;
			}
		}
		p {
			@include bp(sm-max) {
				font-size: 1.6rem;
				@include line-height(16, 24);
			}
		}
		.date {
			display: block;
			font-size: 1.6rem;
			@include line-height(16, 18);
			margin-bottom: 3rem;
			@include bp(sm-max) {
				margin-bottom: 2rem;
			}
			&:last-child {
				margin: 0;
			}
		}
	}
	.blog-detail-banner {
		position: relative;
		padding-bottom: 56.5%;
		overflow: hidden;
		border-radius: 2rem;
		margin-bottom: 2rem;
		@include bp(sm-max) {
			border-radius: 0.6rem;
			margin-bottom: 1.5rem;
		}
		img {
			@extend .absolute-pos;
			@extend .cover-fit;
			@extend .transition;
		}
	}
	.blog-detail-outer {
		margin-bottom: 4.2rem;
		@include bp(sm-max) {
			margin-bottom: 2rem;
		}
		&:last-child {
			margin-bottom: 0;
		}
		h5 {
			font-weight: 600;
			margin-bottom: 2.2rem;
			@include bp(sm-max) {
				font-size: 1.6rem;
				@include line-height(16, 24);
				margin-bottom: 1.7rem;
			}
		}
		p {
			@include bp(sm-max) {
				font-size: 1.6rem;
				@include line-height(16, 24);
			}
		}
	}
}

.policies-page {
	padding: 4rem 0 12rem;
	@include bp(lg-max) {
		padding: 6rem 0 8rem;
	}
	@include bp(smd-max) {
		padding: 2.4rem 0 8rem;
	}
	.policy-outer {
		font-size: 1.6rem;
		@include line-height(16, 24);
		margin-bottom: 4.2rem;
		@include bp(sm-max) {
			margin-bottom: 1.8rem;
		}
		&:last-child {
			margin: 0;
		}
		h3 {
			font-weight: 700;
			margin-bottom: 1.4rem;
			@include bp(sm-max) {
				margin-bottom: 1rem;
			}
		}
		p {
			margin-bottom: 1.3rem;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		.custom-list {
			li {
				@include bp(sm-max) {
					margin-bottom: 0;
				}
			}
		}
		.policy-inner {
			margin-top: 4rem;
			@include bp(sm-max) {
				margin-top: 2.2rem;
			}
			h5 {
				font-weight: 400;
				margin-bottom: 2.1rem;
				@include bp(sm-max) {
					font-size: 1.6rem;
					@include line-height(16, 24);
				}
			}
		}
	}
}

.faq-section {
	padding: 14rem 0;
	@include bp(lg-max) {
		padding: 12rem 0;
	}
	@include bp(smd-max) {
		padding: 10rem 0;
	}
	@include bp(sm-max) {
		padding: 8rem 0;
	}
	h2 {
		text-align: center;
		margin-bottom: 5rem;
		@include bp(sm-max) {
			margin-bottom: 2.8rem;
		}
	}
}

.home-explore-waiheke {
	padding: 13.3rem 0;
	@include bp(lg-max) {
		padding: 12rem 0;
	}
	@include bp(smd-max) {
		padding: 10rem 0;
	}
	@include bp(sm-max) {
		padding: 8rem 0;
	}
	h2 {
		text-align: center;
		margin-bottom: 5rem;
		@include bp(sm-max) {
			margin-bottom: 3.3rem;
		}
	}
}

.booking-steps {
	padding: 14rem 0 0;
	@include bp(lg-max) {
		padding: 11rem 0 0;
	}
	@include bp(smd-max) {
		padding: 10rem 0 0;
	}
	@include bp(sm-max) {
		padding: 8rem 0 0;
	}
	.steps-wrapper {
		position: relative;
		z-index: 0;
		&::before {
			content: "";
			position: absolute;
			left: 11%;
			right: 11%;
			top: 1.4rem;
			height: 6.7rem;
			background-image: url("../images/home-curve.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			z-index: -1;
			@include bp(sm-max) {
				left: 1.3rem;
				right: auto;
				top: 0;
				bottom: 0;
				width: 4.1rem;
				height: auto;
				background-image: url("../images/home-curve-vertical.svg");
			}
		}
		.steps-row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -1.5rem;
			.steps-col {
				width: 33.33%;
				padding: 0 1.5rem;
				text-align: center;
				@include bp(sm-max) {
					width: 100%;
					display: flex;
					text-align: left;
					margin-bottom: 2rem;
					&:last-child {
						margin-bottom: 0;
					}
				}
				.thumb {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					height: 9rem;
					width: 9rem;
					background: $blue;
					border-radius: 0.6rem;
					margin-bottom: 2rem;
					@include bp(sm-max) {
						height: 7rem;
						width: 7rem;
						margin: 0;
					}
					img {
						@include bp(sm-max) {
							max-height: 3.6rem;
						}
					}
				}
				.info {
					@include bp(sm-max) {
						width: calc(100% - 6.7rem);
						padding-left: 2rem;
						margin-right: -0.3rem;
					}
				}
				h3 {
					font-weight: 700;
					margin-bottom: 0.8rem;
					@include bp(sm-max) {
						font-size: 1.8rem;
						@include line-height(18, 26);
						margin-bottom: 0.4rem;
					}
				}
				p {
					font-weight: 500;
					@include bp(smd-max) {
						font-size: 1.6rem;
						@include line-height(16, 24);
					}
				}
			}
		}
	}
}

.largest-selection-section {
	padding: 14rem 0;
	@include bp(lg-max) {
		padding: 12rem 0;
	}
	@include bp(smd-max) {
		padding: 10rem 0;
	}
	@include bp(sm-max) {
		padding: 8rem 0;
	}
	.section-wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
		.content-block {
			width: 39.8%;
			@include bp(smd-max) {
				width: 100%;
				margin-bottom: 3rem;
				text-align: center;
			}
			h2 {
				margin-bottom: 0.7rem;
			}
			p {
				font-weight: 500;
				@include bp(sm-max) {
					font-size: 1.6rem;
					@include line-height(16, 24);
					letter-spacing: 0.02rem;
				}
			}
		}
		.img-block {
			width: 48.82%;
			z-index: 0;
			position: relative;
			@include bp(smd-max) {
				width: 100%;
			}
			.img-inner {
				position: relative;
				overflow: hidden;
				border-radius: 2rem;
				padding-bottom: 80%;
				@include bp(sm-max) {
					border-radius: 1rem;
					padding-bottom: 86.6%;
					box-shadow: 0 0.8rem 2rem rgba($black, 0.15);
				}
				.thumb {
					@extend .absolute-pos;
					@extend .cover-fit;
				}
			}
			.badge-outer {
				height: 18rem;
				width: 18rem;
				position: absolute;
				border-radius: 50%;
				overflow: hidden;
				bottom: -6.1rem;
				left: -6.1rem;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				@include bp(lg-max) {
					bottom: -3.4rem;
					left: 0;
				}
				@include bp(sm-max) {
					height: 11.4rem;
					width: 11.4rem;
				}
				.badge-frame {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					height: 100%;
					width: 100%;
					z-index: -1;
				}
				.animated-text {
					animation: badgeRotate 10s linear infinite;
					@include bp(sm-max) {
						max-width: 8.8rem;
					}
				}
			}
		}
	}
}

@keyframes badgeRotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.car-rental-fleet {
	padding: 8rem 0;
	background-color: $blue;
	@include bp(sm-max) {
		padding: 6.8rem 0;
	}
	h2 {
		text-align: center;
		margin-bottom: 5rem;
		@include bp(sm-max) {
			margin-bottom: 3rem;
		}
	}
	.vehicle-carousel-outer {
		margin-top: 3.9rem;
		@include bp(sm-max) {
			margin-top: 3rem;
		}
		.vehicle-slider {
			overflow: visible;
			.swiper-slide {
				width: 40.2rem;
				max-width: 100%;
				@include bp(sm-max) {
					width: 32rem;
				}
			}
			.slider-navigation {
				.swiper-button-prev,
				.swiper-button-next {
					display: none;
					@include bp(sm-max) {
						display: flex;
					}
				}
			}
		}
	}
	.see-all-btn {
		margin-top: 5rem;
		text-align: center;
		@include bp(sm-max) {
			margin-top: 3.5rem;
		}
		.green-btn {
			width: 18.6rem;
		}
	}
}

.waiheke-local-business {
	padding: 14rem 0;
	@include bp(lg-max) {
		padding: 12rem 0;
	}
	@include bp(smd-max) {
		padding: 9rem 0;
	}
	@include bp(sm-max) {
		padding: 8rem 0;
	}
	h2 {
		display: none;
		@include bp(smd-max) {
			display: block;
			margin-bottom: 2.6rem;
			text-align: center;
		}
	}
	.business-row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5rem;
		.img-block {
			width: 50%;
			padding: 0 1.5rem;
			@include bp(smd-max) {
				width: 100%;
				margin-bottom: 3.6rem;
			}
			.banner-outer {
				position: relative;
				overflow: hidden;
				border-radius: 2rem;
				padding-bottom: 80%;
				@include bp(sm-max) {
					border-radius: 1rem;
					padding-bottom: 89%;
				}
				.banner {
					@extend .absolute-pos;
					@extend .cover-fit;
				}
			}
		}
		.content-block {
			width: 50%;
			padding: 0 1.5rem;
			margin-top: -0.7rem;
			@include bp(smd-max) {
				width: 100%;
			}
			h2 {
				margin-bottom: 3rem;
				display: block;
				@include bp(smd-max) {
					display: none;
				}
			}
			.feature-listing {
				li {
					display: flex;
					margin-bottom: 3rem;
					@include bp(sm-max) {
						margin-bottom: 2rem;
					}
					&:last-child {
						margin-bottom: 0;
					}
					.thumb {
						background: $blue;
						border-radius: 0.6rem;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						height: 7.4rem;
						width: 7.4rem;
						@include bp(sm-max) {
							height: 7rem;
							width: 7rem;
						}
					}
					.info {
						width: calc(100% - 7.4rem);
						padding-left: 2rem;
						@include bp(sm-max) {
							width: calc(100% - 7rem);
						}
						h3 {
							margin-bottom: 0.5rem;
							font-weight: 700;
							@include bp(sm-max) {
								font-size: 1.8rem;
								@include line-height(18, 26);
								margin-bottom: 0.2rem;
							}
						}
						p {
							font-weight: 500;
							@include bp(sm-max) {
								font-size: 1.6rem;
								@include line-height(16, 24);
							}
						}
					}
				}
			}
		}
	}
}
