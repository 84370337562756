.green-btn {
	-webkit-appearance: none;
	font-size: 1.6rem;
	line-height: 1.6rem;
	background: $green;
	color: $white;
	padding: 0 2rem;
	height: 4.4rem;
	border: 0;
	border-radius: 0.4rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@extend .transition;
	&:hover {
		&:not(.disabled) {
			background: lighten($green, 8%);
			color: $white;
		}
	}
	&.disabled {
		opacity: 0.6;
		cursor: not-allowed;
		&:hover {
			color: $white;
		}
	}
	&.sm {
		height: 3.7rem;
	}
	&.lg {
		height: 5rem;
		@include bp(sm-max) {
			height: 4.4rem;
		}
	}
	&.bordered-btn {
		border: 0.1rem solid $grey;
		background-color: transparent;
		color: $grey;
		font-weight: 600;
		&:hover {
			background-color: $grey;
			color: $white;
		}
	}
}

.form-group {
	margin-bottom: 1.9rem;
	label {
		font-size: 1.6rem;
		@include line-height(16, 19);
		display: inline-block;
		margin-bottom: 0.8rem;
		font-weight: 500;
	}
}

textarea.form-control {
	min-height: 10rem;
	resize: none;
	padding: 1.3rem;
	@include bp(sm-max) {
		min-height: 9rem;
	}
}

.form-control {
	border: 0.1rem solid $grey1;
	border-radius: 0.6rem;
	background: transparent;
	height: 5rem;
	padding: 0 1.3rem;
	color: $grey;
	font-size: 1.6rem;
	line-height: 1.9rem;
	outline: 0;
	box-shadow: none;
	@extend .transition;
	@include bp(sm-max) {
		height: 4.4rem;
		padding: 0 1.1rem;
	}
	&:focus {
		background: transparent;
		border-color: $green;
		outline: 0;
		box-shadow: none;
		color: $grey;
	}
	&.has-calendar-icon {
		padding-right: 4rem;
		background-image: url("../images/calendar-icon.svg");
		background-size: 1.8rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 1.5rem) center;
	}
}

.dk-select {
	width: 100%;
	&.dk-select-open-down,
	&.dk-select-open-up {
		.dk-select-options {
			display: block;
			border-color: $grey1;
			padding: 0;
		}
	}
	&.dk-select-open-down {
		.dk-selected {
			border-radius: 0.5rem 0.5rem 0 0;
			&:after {
				@include rotate(180deg);
			}
		}
	}
	&.dk-select-open-up {
		.dk-selected {
			border-radius: 0 0 0.5rem 0.5rem;
		}
	}
	.dk-selected {
		@extend .form-control;
		line-height: 4.6rem;
		padding-right: 2.4rem;
		@include bp(sm-max) {
			line-height: 4rem;
		}
		&:before {
			display: none;
		}
		&:after {
			content: "";
			position: absolute;
			right: 1.2rem;
			top: calc(50% - 0.35rem);
			border: 0;
			margin: 0;
			width: 1.4rem;
			height: 0.7rem;
			background-image: url("../images/down-arrow.svg");
			@extend .bg-props;
			@extend .transition;
			background-size: 1.4rem !important;
			line-height: 0;
		}
	}
	.dk-select-options {
		background: $white;
		box-shadow: 0 0.3rem 0.6rem 0.3rem rgba(0, 0, 0, 0.1);
		.dk-option {
			padding: 0.9rem 1.3rem;
			font-size: 1.6rem;
			line-height: 1;
			&.dk-option-highlight {
				background: $hoverBg;
				color: $grey1;
			}
			&.dk-option-selected {
				background: $green;
				color: $white;
			}
		}
	}
}

.date-time-wrapper {
	display: flex;
	.form-control {
		border-radius: 0.6rem 0 0 0.6rem;
		width: 52.6%;
		@include bp(sm-max) {
			width: 48%;
		}
	}
	.dk-select {
		width: 47.4%;
		@include bp(sm-max) {
			width: 51.6%;
		}
		.dk-selected {
			border-radius: 0 0.6rem 0.6rem 0;
			border-left: 0;
			width: 100%;
		}
	}
}

.datepicker {
	&.form-control {
		padding-right: 4rem;
		background-image: url("../images/calendar-icon.svg");
		background-size: 1.8rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 1.5rem) center;
		&.no-icon {
			background-image: none;
			padding-right: 1.3rem;
		}
	}
	&.datepicker-dropdown {
		font-size: 1.4rem;
	}
	td,
	th {
		width: 3.5rem;
		height: 3.5rem;
	}
	table {
		tr {
			td {
				&.active.active {
					background: $green;
					&:hover {
						background: $green;
					}
				}
				span {
					&.active.active {
						background: $green;
						&:hover {
							background: $green;
						}
					}
				}
			}
		}
	}
}

.custom-checkbox {
	position: relative;
	padding-left: 2.8rem;
	color: $grey;
	@include line-height(18, 21);
	@include bp(sm-max) {
		font-size: 1.6rem;
		@include line-height(16, 24);
	}
	&.has-fixed-width {
		.hidden-input {
			height: 1.8rem;
			width: 1.8rem;
			top: 0.4rem;
			@include bp(Sm-max) {
				top: 0.3rem;
			}
		}
	}
	.hidden-input {
		@extend .absolute-pos;
		cursor: pointer;
		opacity: 0;
		z-index: 1;
		&:checked {
			~ label {
				&::before {
					border-color: $green;
					background-color: $green;
				}
				&:after {
					@include scale(1);
					opacity: 1;
				}
			}
		}
	}
	> label {
		display: block;
		font-weight: 400;
		.checkbox-link {
			color: $green;
			text-decoration: underline;
			font-weight: 600;
		}
		&:before,
		&:after {
			content: "";
			height: 1.8rem;
			width: 1.8rem;
			display: inline-block;
			position: absolute;
			left: 0;
			top: 0.4rem;
			@extend .transition;
			@include bp(sm-max) {
				top: 0.3rem;
			}
		}
		&:before {
			border: 0.1rem solid $light-grey;
		}
		&:after {
			background-image: url("../images/tick-white.svg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: 1.2rem;
			@include scale(0.5);
			opacity: 0;
		}
	}
}
