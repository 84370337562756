.modal {
	--bs-modal-width: 83.5rem;
	&.book-vehicle-modal {
		.modal-dialog {
			--bs-modal-width: 53.6rem;
		}
		.modal-body {
			padding: 4.8rem 5rem;
			@include bp(sm-max) {
				padding: 2.8rem 2rem;
			}
		}
		.form-group {
			margin-bottom: 1.1rem;
			label {
				font-weight: 600;
				font-size: 1.4rem;
				margin-bottom: 1.1rem;
			}
		}
		.date-time-wrapper {
			.form-control {
				width: 47%;
				@include bp(sm-max) {
					width: 50%;
				}
			}
			.custom-select {
				width: 53%;
				@include bp(sm-max) {
					width: 50%;
				}
				.dk-selected {
					width: 100%;
				}
			}
		}
		.btn-col {
			margin-top: 2.1rem;
			@include bp(sm-max) {
				text-align: center;
			}
			.green-btn {
				width: 18.5rem;
				@include bp(sm-max) {
					width: 21.6rem;
				}
			}
		}
	}
	&.payment-modal {
		.modal-dialog {
			--bs-modal-width: 57.8rem;
			.modal-body {
				padding: 0;
				.close-modal {
					svg {
						path {
							fill: $grey;
							@include bp(sm-max) {
								fill: $white;
							}
						}
					}
				}
				.payment-title {
					text-align: center;
					background: $light-grey1;
					padding: 2.5rem;
					border-radius: 2rem 2rem 0 0;
					@include bp(sm-max) {
						padding: 2.2rem 2rem;
					}
					h3 {
						margin-bottom: 1.8rem;
						font-size: 2.2rem;
						line-height: 3rem;
					}
					.payment-icons-wrapper {
						display: inline-flex;
						justify-content: space-between;
						width: 33.3rem;
						max-width: 100%;
						@include bp(sm-max) {
							width: 31.1rem;
							img {
								max-height: 2.6rem;
							}
						}
						.payment-methods {
							display: flex;
							align-items: center;
							li {
								margin-right: 0.9rem;
								line-height: 0;
								@include bp(sm-max) {
									margin-right: 0.7rem;
								}
								&:last-child {
									margin-right: 0;
								}
							}
						}
						.security-icons {
							display: flex;
							align-items: center;
							li {
								margin-right: 0.7rem;
								line-height: 0;
								@include bp(sm-max) {
									margin-right: 0.5rem;
								}
								&:last-child {
									margin-right: 0;
								}
							}
						}
					}
				}
				.iframe-block {
					padding: 1rem;
					iframe {
						height: 40.2rem;
					}
					.img-block {
						text-align: center;
						img {
							display: block;
							margin: 0 auto;
						}
					}
				}
				.payment-footer {
					border-radius: 0 0 2rem 2rem;
					background: $light-grey1;
					text-align: center;
					font-size: 1.6rem;
					line-height: 1.9rem;
					color: $grey;
					padding: 1.2rem 2.5rem;
					@include bp(sm-max) {
						line-height: 2.4rem;
						padding: 1.2rem 2rem;
					}
				}
			}
		}
	}
}

.modal-backdrop {
	--bs-backdrop-opacity: 0.3;
}

.modal-sm {
	--bs-modal-width: 47.7rem;
	@include bp(sm-max) {
		width: 100%;
	}
}

.modal-dialog {
	// margin-top: 0.5rem;
	// margin-bottom: 0.5rem;
	&.modal-dialog-centered {
		@include bp(sm-max) {
			margin: 0;
			align-items: flex-end;
			min-height: 100%;
			padding-top: 5rem;
			max-width: inherit;
		}
	}
}

.modal-content {
	border: 0;
	box-shadow: 0 0.3rem 0.6rem 0.3rem rgba(0, 0, 0, 0.1);
	border-radius: 2rem;
	background: $white;
	@include bp(sm-max) {
		border-radius: 2rem 2rem 0 0;
	}
}

.modal-body {
	padding: 2.5rem;
	@include bp(sm-max) {
		padding: 2rem 2rem 3rem;
	}
	@include bp(xxs-max) {
		padding: 1.2rem 1.2rem 2rem;
	}
	&.lg-pad {
		padding: 5rem;
		@include bp(sm-max) {
			padding: 2rem 2rem 3rem;
		}
	}
	.close-modal {
		position: absolute;
		line-height: 1;
		display: inline-flex;
		top: 2.5rem;
		right: 2rem;
		cursor: pointer;
		@include bp(sm-max) {
			top: -3.6rem;
			right: auto;
			left: 50%;
			@include translateX(-50%);
		}
		svg {
			path {
				fill: $light-grey5;
				@include bp(sm-max) {
					fill: $white;
				}
			}
		}
	}
}

.booking-request-info {
	text-align: center;
	.thumb {
		display: inline-flex;
		margin-bottom: 2.8rem;
		@include bp(sm-max) {
			max-width: 8rem;
			margin-bottom: 1.6rem;
		}
	}
	h3 {
		@include line-height(22, 30);
		margin-bottom: 1rem;
		@include bp(sm-max) {
			font-size: 2.2rem;
		}
	}
	p {
		font-size: 1.6rem;
		@include line-height(16, 24);
		margin-bottom: 2.2rem;
		@include bp(sm-max) {
			margin-bottom: 1.8rem;
		}
	}
	.booking-btn {
		text-align: center;
		.green-btn {
			width: 21.6rem;
			max-width: 100%;
		}
	}
}
