//Break points
@mixin bp($point) {
	
	$xxs-max: "(max-width: 359.98px)";
	$xsm-max:"(max-width: 413.98px)";
	$xs-max: "(max-width: 575.98px)";
	$sm-max: "(max-width: 767.98px)";
	$smd-max: "(max-width: 991.98px)";
	$md-max: "(max-width: 1024.98px)";
	$lg-max: "(max-width: 1199.98px)";
	$xlg-max: "(max-width: 1365.98px)";
	
	@if $point == xxs-max {
		@media #{$xxs-max} { @content; }
	}
	@if $point == xsm-max {
		@media #{$xsm-max} { @content; }
	}
	@else if $point == xs-max {
		@media #{$xs-max} { @content; }
	}
	@else if $point == sm-max {
		@media #{$sm-max} { @content; }
	}
	@else if $point == smd-max {
		@media #{$smd-max} { @content; }
	}
	@else if $point == md-max {
		@media #{$md-max} { @content; }
	}
	@else if $point == lg-max {
		@media #{$lg-max} { @content; }
	}
	@else if $point == xlg-max {
		@media #{$xlg-max} { @content; }
	}

}