$black: #000000;
$white: #ffffff;
$green: #05380c;
$grey: #202020;
$grey1: #464646;
$light-grey: #959595;
$light-grey1: #f1f1f1;
$light-grey3: #e2e2e2;
$light-grey4: #e3e3e3;
$light-grey5: #c2c2c2;
$light-grey6: #f2f2f2;
$blue: #dcfff3;
$blue1: #bcd1e3;
$hoverBg: #eee;
