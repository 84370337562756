h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
	font-weight: normal;
	margin-bottom: 1.5rem;
	&:last-child {
		margin-bottom: 0;
	}
}

.lg-font {
	font-size: 2.2rem;
	@include line-height(22, 34);
	@include bp(smd-max) {
		font-size: 2rem;
	}
	@include bp(sm-max) {
		font-size: 1.8rem;
		@include line-height(18, 26);
	}
}

h1,
.h1 {
	font-size: 4rem;
	@include line-height(40, 48);
	font-weight: 700;
	color: $grey;
	@include bp(lg-max) {
		font-size: 3.6rem;
	}
	&.lg {
		font-size: 4.8rem;
		@include line-height(48, 56);
		font-weight: 600;
		@include bp(lg-max) {
			font-size: 4.2rem;
		}
		@include bp(smd-max) {
			font-size: 3.6rem;
		}
		@include bp(sm-max) {
			font-size: 3.2rem;
			@include line-height(32, 38);
		}
	}
}

h2,
.h2 {
	font-size: 3rem;
	@include line-height(30, 36);
	font-weight: 700;
	color: $grey;
	@include bp(smd-max) {
		font-size: 2.7rem;
	}
	@include bp(sm-max) {
		font-size: 2.4rem;
		@include line-height(24, 30);
	}
}

h3,
.h3 {
	font-size: 2.2rem;
	@include line-height(22, 26);
	font-weight: 600;
	color: $grey;
	@include bp(sm-max) {
		font-size: 2rem;
		@include line-height(20, 28);
	}
}

h4,
.h4 {
	font-size: 2rem;
	@include line-height(20, 24);
	font-weight: 600;
	color: $grey;
}

h5,
.h5 {
	font-size: 1.8rem;
	@include line-height(18, 21);
	font-weight: 500;
	color: $grey;
}

h6,
.h6 {
	font-size: 1.6rem;
	@include line-height(16, 19);
	font-weight: 400;
	color: $grey;
}

.title-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5rem;
	text-align: center;
	@include bp(sm-max) {
		display: block;
		margin-bottom: 3.6rem;
	}
	.icon {
		line-height: 0;
		margin-right: 1rem;
		display: inline-block;
		@include bp(sm-max) {
			margin-right: 0.3rem;
			position: relative;
			top: -6px;
			width: 2.4rem;
		}
	}
}
