@font-face {
	font-family: 'Matter';
	src: url('../fonts/Matter-Bold.eot');
	src: local('Matter Bold'), local('Matter-Bold'),
			url('../fonts/Matter-Bold.eot?#iefix') format('embedded-opentype'),
			url('../fonts/Matter-Bold.woff2') format('woff2'),
			url('../fonts/Matter-Bold.woff') format('woff'),
			url('../fonts/Matter-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Matter';
	src: url('../fonts/Matter-SemiBold.eot');
	src: local('Matter SemiBold'), local('Matter-SemiBold'),
			url('../fonts/Matter-SemiBold.eot?#iefix') format('embedded-opentype'),
			url('../fonts/Matter-SemiBold.woff2') format('woff2'),
			url('../fonts/Matter-SemiBold.woff') format('woff'),
			url('../fonts/Matter-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Matter';
	src: url('../fonts/Matter-Heavy.eot');
	src: local('Matter Heavy'), local('Matter-Heavy'),
			url('../fonts/Matter-Heavy.eot?#iefix') format('embedded-opentype'),
			url('../fonts/Matter-Heavy.woff2') format('woff2'),
			url('../fonts/Matter-Heavy.woff') format('woff'),
			url('../fonts/Matter-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Matter';
	src: url('../fonts/Matter-Medium.eot');
	src: local('Matter Medium'), local('Matter-Medium'),
			url('../fonts/Matter-Medium.eot?#iefix') format('embedded-opentype'),
			url('../fonts/Matter-Medium.woff2') format('woff2'),
			url('../fonts/Matter-Medium.woff') format('woff'),
			url('../fonts/Matter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Matter';
	src: url('../fonts/Matter-Light.eot');
	src: local('Matter Light'), local('Matter-Light'),
			url('../fonts/Matter-Light.eot?#iefix') format('embedded-opentype'),
			url('../fonts/Matter-Light.woff2') format('woff2'),
			url('../fonts/Matter-Light.woff') format('woff'),
			url('../fonts/Matter-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Matter';
	src: url('../fonts/Matter-Regular.eot');
	src: local('Matter Regular'), local('Matter-Regular'),
			url('../fonts/Matter-Regular.eot?#iefix') format('embedded-opentype'),
			url('../fonts/Matter-Regular.woff2') format('woff2'),
			url('../fonts/Matter-Regular.woff') format('woff'),
			url('../fonts/Matter-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}


$regularFont: 'Matter', sans-serif;