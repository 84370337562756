.site-footer {
	font-size: 1.6rem;
	@include line-height(16, 24);
	.top-footer {
		padding: 6rem 0 4rem;
		@include bp(sm-max) {
			padding: 7rem 0 2.8rem;
		}
		.top-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0 -1.5rem;
			h6 {
				font-weight: 600;
				margin-bottom: 1.9rem;
			}
			.logo-col {
				width: 33.5%;
				padding: 0 1.5rem;
				@include bp(smd-max) {
					width: 100%;
					text-align: center;
					margin-bottom: 4.2rem;
				}
				.footer-logo {
					display: inline-block;
					margin-bottom: 1.2rem;
					@include bp(smd-max) {
						margin-bottom: 2.3rem;
					}
				}
			}
			.links-col {
				width: 16%;
				padding: 0 1.5rem;
				@include bp(smd-max) {
					width: 45%;
					&.lg {
						width: 55%;
					}
				}
				ul {
					li {
						margin-bottom: 1.6rem;
						&:last-child {
							margin-bottom: 0;
						}
						a {
							color: $grey1;
							&:hover {
								color: $green;
							}
						}
					}
				}
			}
			.contact-col {
				width: 24.9%;
				padding: 0 1.5rem;
				@include bp(smd-max) {
					width: 100%;
					margin-top: 4.2rem;
				}
				.contact-list {
					margin-top: -0.6rem;
					li {
						margin-bottom: 1.5rem;
						display: flex;
						&:last-child {
							margin-bottom: 0;
						}
						.icon {
							flex: 0 0 1.7rem;
							max-width: 1.7rem;
						}
						.content {
							flex: 0 0 calc(100% - 1.7rem);
							max-width: calc(100% - 1.7rem);
							padding-left: 1.5rem;
							word-break: break-word;
							a {
								color: $grey1;
								&:hover {
									color: $green;
								}
							}
						}
					}
				}
			}
		}
	}
	.bottom-footer {
		.bottom-inner {
			border-top: 0.1rem solid rgba($grey1, 0.2);
			padding: 2rem 0 3rem;
			text-align: center;
			p {
				font-size: 1.4rem;
				@include line-height(14, 23);
			}
		}
	}
}

.sticky-content-widget {
	position: fixed;
	bottom: 4rem;
	z-index: 99;
	right: -50rem;
	width: 47.8rem;
	max-width: 100%;
	background: $white;
	box-shadow: -1rem -1rem 3rem rgba(0, 0, 0, 0.2), -1rem -1rem 3rem rgba(0, 0, 0, 0.2);
	border-radius: 1rem 0px 0px 1rem;
	padding: 3.8rem 12.8rem 3.8rem 2.6rem;
	color: $grey;
	font-size: 2rem;
	line-height: 2.8rem;
	letter-spacing: 0.01rem;
	@extend .transition;
	@include bp(sm-max) {
		right: -37rem;
		width: 35rem;
		padding: 1.5rem 9rem 1.5rem 2rem;
		font-size: 1.6rem;
		line-height: 2.4rem;
		bottom: 2rem;
	}
	.content-widget-visible & {
		right: 0;
	}
}

.sticky-img-widget {
	position: fixed;
	display: flex;
	right: calc(-50rem - 2.6rem);
	bottom: 6.6rem;
	z-index: 100;
	@extend .transition;
	@include bp(sm-max) {
		width: 7rem;
		bottom: 3.6rem;
	}
	.img-widget-visible & {
		right: 2.6rem;
		@include bp(sm-max) {
			right: 1.5rem;
		}
	}
	&::after {
		animation-delay: 0.5s !important;
	}
}

@keyframes pulse-anim {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(5, 56, 12, 0.7);
	}

	50% {
		transform: scale(1.2);
		box-shadow: 0 0 0 10px rgba(5, 56, 12, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(5, 56, 12, 0);
	}
}

body:not(.content-widget-visible) .sticky-img-widget::before,
body:not(.content-widget-visible) .sticky-img-widget::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	animation: pulse-anim 3s infinite;
	border-radius: 50%;
}

.secondary-footer {
	padding: 2rem 0;
	background-color: $blue;
	.bottom-contact {
		font-size: 1.4rem;
		@include line-height(14, 23);
		.bottom-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0 -1.5rem;
			@include bp(smd-max) {
				text-align: center;
			}
			> * {
				@include bp(smd-max) {
					width: 100%;
				}
			}
			.created-by {
				a {
					color: $grey1;
					font-weight: 500;
				}
			}
			.bottom-links {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				@include bp(smd-max) {
					justify-content: center;
					margin: 1rem 0;
				}
				li {
					margin-right: 1.6rem;
					position: relative;
					@include bp(sm-max) {
						width: 100%;
						margin-right: 0;
						margin-bottom: 0.2rem;
					}
					&:last-child {
						margin: 0;
						&:after {
							display: none;
						}
					}
					&:after {
						content: "";
						position: absolute;
						right: -0.8rem;
						top: 0.6rem;
						width: 0.1rem;
						height: 1.2rem;
						background-color: $grey1;
						@include bp(sm-max) {
							display: none;
						}
					}
					a {
						color: $grey1;
					}
				}
			}
		}
	}
}
