.site-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 11;
	@extend .transition;
	.page-scrolled & {
		background-color: $white;
		box-shadow: 0 0.1rem 0 rgba(0, 39, 76, 0.2);
	}
	.menu-open & {
		@include bp(smd-max) {
			background-color: $green;
		}
	}

	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		height: 8rem;
		@include bp(smd-max) {
			height: 7.6rem;
		}
	}
	.site-logo {
		position: absolute;
		left: 0.8rem;
		top: 0;
		z-index: 21;
		@include bp(smd-max) {
			width: 11.9rem;
			left: 1.5rem;
		}
	}
	.green-btn {
		position: absolute;
		right: 1.5rem;
		top: 50%;
		@include translateY(-50%);
		width: 12.3rem;
		@include bp(smd-max) {
			display: none;
		}
	}
	nav {
		@extend .transition;
		@include bp(smd-max) {
			position: fixed;
			right: -100%;
			top: 7.6rem;
			bottom: 0;
			width: 100%;
			background-color: $white;
			padding: 1.2rem 0 17.5rem;
			overflow: auto;
			z-index: 20;
			background-image: url('../images/mobile-menu-bg.svg');
			background-position: bottom center;
			background-repeat: no-repeat;
			.menu-open & {
				right: 0;
			}
		}
		ul {
			display: flex;
			align-items: center;
			@include bp(smd-max) {
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
			}
			li {
				margin-right: 4rem;
				@include bp(smd-max) {
					margin-right: 0;
					width: 100%;
					border-bottom: 0.1rem solid $blue;
				}
				&:last-child {
					margin: 0;
				}
				a {
					font-size: 1.6rem;
					@include line-height(16, 30);
					color: $grey;
					font-weight: 500;
					display: inline-flex;
					align-items: center;
					@include bp(smd-max) {
						display: flex;
						padding: 1.5rem 2rem;
						width: 100%;
						background-color: $white;
					}
					.icon {
						line-height: 0;
						margin-right: 0.2rem;
						@include bp(sm-max) {
							margin-right: 0;
							margin-left: 0.2rem;
							order: 2;
						}
					}
					&:hover {
						color: $green;
					}
					&.active {
						color: $green;
						font-weight: 900;
					}
				}
			}
		}
	}
}

.hamburger-menu {
	width: 2rem;
	height: 1.8rem;
	z-index: 99;
	position: fixed;
	right: 2.7rem;
	top: 3.1rem;
	.nav-up & {
		top: -3.2rem;
	}
	@media (min-width: 992px) {
		display: none;
	}
	span {
		width: 100%;
		height: 0.2rem;
		background: $black;
		display: block;
		border-radius: 0.3rem;
		@extend .transition;
		.menu-open & {
			background: $white;
			&:first-child {
				transform: translate(0, 0.8rem) rotate(45deg);
			}
			&:last-child {
				transform: translate(0, 0.4rem) rotate(-45deg);
			}
			&:nth-child(2) {
				opacity: 0;
			}
		}
		&:nth-child(2) {
			@include translateY(0.4rem);
		}
		&:last-child {
			@include translateY(0.8rem);
		}
	}
}
