.vehicle-listing {
	padding: 12rem 0 13.6rem;
	@include bp(smd-max) {
		padding: 10rem 0 8.4rem;
	}
	h2 {
		margin-bottom: 6rem;
		text-align: center;
		@include bp(lg-max) {
			margin-bottom: 4rem;
		}
		@include bp(sm-max) {
			margin-bottom: 3.2rem;
		}
	}
	.vehicle-category {
		margin-bottom: 4rem;
		@include bp(sm-max) {
			margin-bottom: 3rem;
		}
	}
	.vehicle-listing-grid {
		.vehicle-row {
			margin-bottom: -2.9rem;
			@include bp(sm-max) {
				margin-bottom: -1.9rem;
			}
			&.deal-row {
				@include bp(sm-max) {
					margin-bottom: -2.3rem;
				}
			}
			.vehicle-col {
				margin-bottom: 2.9rem;
				@include bp(sm-max) {
					margin-bottom: 1.9rem;
				}
				&.deal-col {
					@include bp(sm-max) {
						margin-bottom: 2.3rem;
					}
				}
				.vehicle-card {
					height: 100%;
				}
			}
		}
	}
}

.vehicle-category-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: -1rem;
	@include bp(sm-max) {
		margin: 0 -1rem -1rem;
	}
	li {
		margin-right: 2rem;
		margin-bottom: 1rem;
		@include bp(sm-max) {
			margin-right: 1rem;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

.vehicle-card {
	background: $white;
	box-shadow: 0 0.2rem 2rem rgba(44, 54, 137, 0.1);
	border-radius: 0.6rem;
	overflow: hidden;
	&.is-deal {
		.vehicle-thumb {
			@include bp(sm-max) {
				padding-bottom: 57%;
			}
		}
		.vehicle-details {
			padding: 1.3rem 2rem 1.6rem;
			@include bp(sm-max) {
				padding: 1.9rem 1.6rem 1.6rem;
			}
		}
	}
	&.horizontal {
		display: flex;
		box-shadow: none;
		flex-wrap: wrap;
		&.is-sm {
			.vehicle-thumb {
				width: 40%;
				padding-bottom: 26.6%;
				@include bp(sm-max) {
					width: 100%;
					padding-bottom: 67.3%;
				}
				@media (max-width: 767.98px) and (orientation: landscape) {
					padding-bottom: 40%;
				}
			}
			.vehicle-details {
				width: 60%;
				@include bp(sm-max) {
					width: 100%;
				}
				.inner-info {
					padding-bottom: 0;
					margin-bottom: 0;
					border: 0;
					.config-list {
						li {
							width: 41.2%;
							@include bp(lg-max) {
								width: 50%;
							}
							@include bp(sm-max) {
								width: 53%;
							}
							&:nth-child(even) {
								width: 58.8%;
								@include bp(lg-max) {
									width: 50%;
								}
								@include bp(sm-max) {
									width: 47%;
								}
							}
						}
					}
				}
			}
		}
		.vehicle-thumb {
			width: 48.03%;
			padding-bottom: 32%;
			border-radius: 0.6rem;
			align-self: flex-start;
			@include bp(sm-max) {
				width: 100%;
				padding-bottom: 66.87%;
			}
			@media (max-width: 767.98px) and (orientation: landscape) {
				padding-bottom: 40%;
			}
		}
		.vehicle-details {
			width: 51.97%;
			padding: 0 0 0 2.2rem;
			@include bp(sm-max) {
				width: 100%;
				padding: 0;
				margin-top: 1.5rem;
			}
			.inner-info {
				padding-bottom: 4.5rem;
				margin-bottom: 1.6rem;
				@include bp(sm-max) {
					padding-bottom: 2rem;
				}
				h5 {
					@extend .h3;
					margin-bottom: 2.3rem;
					padding-right: 3rem;
					@include bp(sm-max) {
						font-size: 2.2rem;
						@include line-height(22, 30);
						margin-bottom: 1.5rem;
						padding-right: 0;
					}
				}
				.config-list {
					margin-bottom: -2rem;
					@include bp(sm-max) {
						margin-bottom: -1.6rem;
					}
					li {
						width: 52%;
						margin-bottom: 2rem;
						@include bp(sm-max) {
							width: 53%;
							margin-bottom: 1.6rem;
						}
						&:nth-child(even) {
							width: 48%;
							@include bp(sm-max) {
								width: 47%;
							}
						}
					}
				}
			}
		}
	}
	&.disabled {
		background: $light-grey6;
		.vehicle-thumb {
			pointer-events: none;
			&::before {
				content: "";
				@extend .absolute-pos;
				z-index: 1;
				background-color: rgba($black, 0.2);
			}
		}
		.vehicle-details {
			.inner-info {
				h5 {
					a {
						pointer-events: none;
					}
				}
			}
		}
	}
	.vehicle-thumb {
		display: block;
		overflow: hidden;
		position: relative;
		padding-bottom: 75%;
		@media (max-width: 767.98px) and (orientation: landscape) {
			padding-bottom: 40%;
		}
		&:hover {
			.vehicle-img {
				@include scale(1.05);
			}
		}
		.vehicle-img {
			@extend .absolute-pos;
			@extend .cover-fit;
			@extend .transition;
		}
		.chip {
			position: absolute;
			left: 1.6rem;
			top: 1.6rem;
			z-index: 1;
		}
	}
	.vehicle-details {
		padding: 2rem 1.6rem 2.2rem;
		@include bp(sm-max) {
			padding: 1.5rem 1.6rem 1.8rem;
		}
		@include bp(xxs-max) {
			padding: 1.5rem 1rem 1.8rem;
		}
		.inner-info {
			padding-bottom: 1.7rem;
			border-bottom: 0.1rem solid $light-grey3;
			margin-bottom: 1.7rem;
			h5 {
				margin-bottom: 2.1rem;
				font-weight: 600;
				@include bp(sm-max) {
					margin-bottom: 1.6rem;
				}
				a {
					color: $grey;
					display: block;
					&:hover {
						color: $green;
					}
				}
			}
			.config-list {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: -1.2rem;
				li {
					width: 54.5%;
					margin-bottom: 1.2rem;
					display: flex;
					font-size: 1.6rem;
					@include line-height(16, 24);
					@include bp(sm-max) {
						width: 50.5%;
					}
					&:nth-child(even) {
						width: 45.5%;
						@include bp(sm-max) {
							width: 49.5%;
						}
					}
					.icon {
						height: 2.4rem;
						width: 2.4rem;
						display: inline-flex;
						align-items: center;
						justify-content: center;
					}
					.config {
						width: calc(100% - 2.4rem);
						padding-left: 0.8rem;
						@extend .truncated-text;
					}
				}
			}
		}
		.deals-info {
			color: $grey;
			h4 {
				margin-bottom: 0.7rem;
				@include bp(sm-max) {
					font-size: 1.8rem;
					line-height: 2.6rem;
					margin-bottom: 0.5rem;
				}
			}
			p {
				font-size: 1.6rem;
				@include line-height(16, 24);
			}
		}
		.booking-block {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		.pricing {
			width: calc(100% - 11.2rem);
			padding-right: 0.5rem;
			p {
				font-size: 1.6rem;
				@include line-height(16, 16);
				display: flex;
				align-items: center;
				margin-bottom: 0.8rem;
				&:last-child {
					margin: 0;
				}
				&.day-price {
					strong {
						color: $grey;
						font-size: 2rem;
					}
				}
				strong {
					font-size: 1.8rem;
					margin-right: 0.5rem;
				}
			}
		}
		.book-btn {
			.green-btn {
				padding: 0 1.9rem;
				width: 11.2rem;
			}
		}
	}
}

.booking-page {
	padding: 6rem 0 12rem;
	@include bp(smd-max) {
		padding: 3.5rem 0 0;
	}
	@include bp(sm-max) {
		padding: 2.3rem 0 0;
	}
	.booking-wrapper {
		.booking-row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -1.5rem;
			.booking-forms {
				padding: 0 1.5rem;
				width: calc(100% - 32.4rem);
				@include bp(smd-max) {
					width: 100%;
				}
				.vehicle-detail-wrapper {
					margin-bottom: 3rem;
				}
				.timer-block {
					padding: 1.5rem 2rem;
					background: $blue;
					border-radius: 0.6rem;
					margin-bottom: 3rem;
					font-size: 1.6rem;
					@include line-height(16, 24);
					color: $grey;
					letter-spacing: 0.01rem;
					@include bp(smd-max) {
						margin: 0 -2rem 3rem;
						border-radius: 0;
						padding: 1.2rem 2rem;
						@include line-height(16, 20);
					}
					.time {
						font-weight: 500;
					}
				}
				.booking-card {
					border-radius: 2rem;
					overflow: hidden;
					background: $white;
					box-shadow: 0 0.2rem 2rem rgba(44, 54, 137, 0.1);
					margin-bottom: 3rem;
					@include bp(smd-max) {
						border-radius: 0;
						box-shadow: none;
						margin: 0 -2rem 0.7rem;
					}
					&:last-child {
						margin-bottom: 0;
					}
					.booking-title {
						background: $blue;
						padding: 1.9rem 2rem;
						@include bp(smd-max) {
							padding: 1.6rem 2rem;
						}
						@include bp(sm-max) {
							padding: 1.5rem 2rem;
						}
						h4 {
							@include bp(sm-max) {
								font-size: 1.8rem;
							}
						}
					}
					.booking-card-body {
						padding: 1.9rem 2rem 2.4rem;
						.payment-icons-wrapper {
							display: inline-flex;
							justify-content: space-between;
							background: $light-grey1;
							border-radius: 6px;
							padding: 1.3rem 2rem;
							margin-bottom: 1.5rem;
							width: 44.3rem;
							max-width: 100%;
							@include bp(sm-max) {
								padding: 0.8rem 1.6rem;
								img {
									max-height: 2.6rem;
								}
							}
							.payment-methods {
								display: flex;
								align-items: center;
								li {
									margin-right: 0.9rem;
									line-height: 0;
									@include bp(sm-max) {
										margin-right: 0.7rem;
									}
									&:last-child {
										margin-right: 0;
									}
								}
							}
							.security-icons {
								display: flex;
								align-items: center;
								li {
									margin-right: 0.7rem;
									line-height: 0;
									@include bp(sm-max) {
										margin-right: 0.5rem;
									}
									&:last-child {
										margin-right: 0;
									}
								}
							}
						}
						.checkbox-row {
							display: flex;
							justify-content: space-between;
							margin-bottom: 2.9rem;
							@include bp(smd-max) {
								margin-bottom: 2.5rem;
							}
							@include bp(sm-max) {
								margin-bottom: 1.6rem;
								font-size: 1.6rem;
								@include line-height(16, 24);
							}
							&:last-child {
								margin-bottom: 0;
							}
							.custom-checkbox {
								width: calc(100% - 7rem);
							}
							.price {
								font-weight: 600;
								color: $grey;
								width: 7rem;
								text-align: right;
							}
						}
						.booking-btn {
							margin-top: 0.8rem;
							.green-btn {
								width: 21.6rem;
								max-width: 100%;
								padding: 0 0.8rem;
							}
						}
					}
				}
			}

			.booking-summary {
				padding: 0 1.5rem;
				width: 32.4rem;
				@include bp(smd-max) {
					width: 100%;
					margin-top: 5.7rem;
				}
				.summary-wrapper {
					background: $light-grey1;
					box-shadow: 0 0.2rem 2rem rgba(44, 54, 137, 0.1);
					border-radius: 0.6rem;
					padding: 2rem 1.5rem;
					@include bp(smd-max) {
						padding: 2.8rem 2rem;
						margin: 0 -2rem;
						border-radius: 0;
					}
					h4 {
						margin-bottom: 2.1rem;
						letter-spacing: 0.01rem;
						@include bp(sm-max) {
							font-size: 1.8rem;
							@include line-height(18, 26);
							margin-bottom: 2rem;
						}
					}
					.summary-outer {
						padding-bottom: 1.8rem;
						margin-bottom: 1.8rem;
						border-bottom: 0.1rem dashed $grey;
						&:last-child {
							padding-bottom: 0;
							margin-bottom: 0;
							border-bottom: 0;
						}
						.location-edit {
							margin-left: 2.6rem;
							font-weight: 500;
							text-decoration: underline;
							margin-top: 1.8rem;
						}
						.price-breakdown {
							li {
								display: flex;
								justify-content: space-between;
								font-size: 1.6rem;
								line-height: 1.9rem;
								margin-bottom: 2rem;
								color: $grey;
								@include bp(sm-max) {
									margin-bottom: 1.8rem;
								}
								&:last-child {
									margin-bottom: 0;
								}
								&.total-row {
									.price {
										font-size: 1.8rem;
										letter-spacing: -0.05rem;
										@include bp(sm-max) {
											font-size: 1.6rem;
										}
									}
								}
								.price-label {
									width: calc(100% - 8.6rem);
								}
								.price {
									width: 8.6rem;
									text-align: right;
									font-weight: 600;
								}
								.gst {
									color: $grey1;
									display: block;
									margin-top: 0.6rem;
								}
							}
						}
					}
				}
			}
		}
	}
}
